import React from "react"
import { graphql } from "gatsby"
import marked from "marked"

import Contact from "../components/Contact"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout homePage={true}>
      <SEO title="IM Sara Woudenberg" />
      <section id="home">
        <div className="image">
          <div className="wrapper">
            <h1>
              IM Sara <br /> Woudenberg
            </h1>
            <p className="home-p">
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(frontmatter.sara_home_text),
                }}
              ></div>
            </p>
          </div>
        </div>
      </section>
      <section id="about">
        <div className="container-xl wrapper">
          <div className="inner">
            <img alt="heart" className="dec" src="/images/heart.svg"></img>
            <h1>{frontmatter.sara_about_title}</h1>
            <div
              className="about-p"
              dangerouslySetInnerHTML={{
                __html: marked(
                  `<img src=${frontmatter.sara_about_image} alt="Sara" /></p><p>` +
                    frontmatter.sara_about_text
                ),
              }}
            ></div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container-xl wrapper">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h1>{frontmatter.sara_contact_title}</h1>
              <p>{frontmatter.sara_contact_text}</p>
              <img alt="mail" src="/images/mail.svg"></img>
            </div>
            <div className="col-12 col-lg-6">
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        sara_home_text
        sara_about_title
        sara_about_text
        sara_about_image
        sara_contact_title
        sara_contact_text
      }
    }
  }
`
