import React from "react"
import $ from "jquery"

// Contact component render contact form
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactMessage: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    }

    this._handleSubmit = this._handleSubmit.bind(this)
    this._handleChangeEmail = this._handleChangeEmail.bind(this)
    this._handleChangeMsg = this._handleChangeMsg.bind(this)
    this._handleChangeName = this._handleChangeName.bind(this)
    this._handleChangeNumber = this._handleChangeNumber.bind(this)
  }

  _handleChangeMsg(e) {
    this.setState({
      contactMessage: e.target.value,
    })
  }

  _handleChangeName(e) {
    this.setState({
      contactName: e.target.value,
    })
  }

  _handleChangeEmail(e) {
    this.setState({
      contactEmail: e.target.value,
    })
  }

  _handleChangeNumber(e) {
    this.setState({
      contactNumber: e.target.value,
    })
  }

  _handleSubmit(e) {
    e.preventDefault()
    this.setState({
      contactEmail: "",
      contactMessage: "",
      contactName: "",
      contactNumber: "",
    })

    $.ajax({
      url:
        process.env.NODE_ENV !== "production"
          ? "/getMail"
          : "https://imsarawoudenberg.nl/mailer.php",
      type: "POST",
      data: {
        form_email: this.state.contactEmail,
        form_msg: this.state.contactMessage,
        form_number: this.state.contactNumber,
        form_name: this.state.contactName,
      },
      cache: false,
      success: function (data) {
        // Success..
        $("#formContact").slideUp()
        $("#formContact").after(
          "<div class='message-sent'><div><h1>Bericht ontvangen!</h1><p>Ik zal zo snel mogelijk reageren.</p></div></div>"
        )
      }.bind(this),
      // Fail..
      error: function (xhr, status, err) {
        console.log(xhr, status)
        console.log(err)
        console.log(
          this.state.contactEmail + this.state.contactMessage + "fail"
        )
      }.bind(this),
    })
  }

  render() {
    return (
      <form className="form" onSubmit={this._handleSubmit} id="formContact">
        <div className="row">
          <div className="col-12">
            <label className="form-label" htmlFor="formName">
              Naam
            </label>
            <input
              id="formName"
              type="text"
              name="formName"
              value={this.state.contactName}
              onChange={this._handleChangeName}
              required
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-6">
            <label className="form-label" htmlFor="formEmail">
              E-mailadres
            </label>
            <input
              id="formEmail"
              type="email"
              name="formEmail"
              value={this.state.contactEmail}
              onChange={this._handleChangeEmail}
              required
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-6">
            <label className="form-label" htmlFor="formNumber">
              Telefoonnummer
            </label>
            <input
              id="formNumber"
              type="tel"
              name="formNumber"
              value={this.state.contactNumber}
              onChange={this._handleChangeNumber}
              required
              className="form-control"
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="formMsg">
              Bericht
            </label>
            <textarea
              className="form-control"
              id="formMsg"
              name="formMsg"
              rows="4"
              cols="40"
              value={this.state.contactMessage}
              onChange={this._handleChangeMsg}
              required
            ></textarea>
          </div>
        </div>
        <input
          type="submit"
          value="Versturen!"
          className="btn--cta"
          id="btn-submit"
        />
      </form>
    )
  }
}
